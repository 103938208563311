import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
//import Button from 'common/src/components/Button';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import Particles from '../../Agency/Particle';
//import BannerWrapper, { DiscountLabel } from './bannerSection.style';
import BannerWrapper from './bannerSection.style';
//import TextWrapper from './bannerSection.style';

const BannerSection = ({
  row,
  col,
  title,
  btnStyle,
  description,
  discountText,
  discountAmount,
  outlineBtnStyle,
}) => {
  const ButtonGroup = () => (
    <Fragment>
      {/*<Button title="GET STARTED" {...btnStyle} />
       <Button
        title="WATCH WORKS"
        variant="textButton"
        icon={<i className="flaticon-next" />}
        {...outlineBtnStyle}
      /> */}
    </Fragment>
  );
  return (
    <BannerWrapper>
      <Particles />
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <h1 style={{ fontSize: "65px", fontWeight: "500", margin: "0", color: "#3D636C" }}>Everyone Benefits From Employee Wellness Programs</h1>
            <FeatureBlock
              title={
                <Heading
                  content=""
                  {...title}
                />
              }
              description={
                <Fragment>
                  <Text
                    content="Employee wellness programs are good for employers as well as employees. We know that stronger and 
                  healthier employees benefit the company with less spent in healthcare needs and less time away from work. 
                  And by reducing absenteeism, wellness programs also help increase productivity."
                    {...description}
                  />
                </Fragment>
              }
              button={<ButtonGroup />}
            />



          </Box>
        </Box>
        <div id="article-content">
          <Heading as="h2" content="There Is No One-Size-Fits-All" />
          <Text content="We serve organizations that want to lower various health risks for their employees. Wellness programs can range from online programs, to offsite programs, to services offered onsite in the workplace. For example, one California company set aside a room for massage where employees could de-stress if needed during the day. This “perk” was offered at no cost to the employee and was very beneficial for the company. However, wellness programs can be designed in many different ways to fit your corporate culture and your employees’ unique needs." />
          <br />
          <Text content="These days, with much of the workforce clocking in from home, workplace wellness can include options for remote workers. After all, sitting for long hours at the computer can cause health issues from being too sedentary -- whether you’re at the office or at home attending a Zoom meeting. Your employee wellness program can be designed for the way your staff is working and the specific health risks they face. " />
          <br /><br />

          <Heading as="h2" content="Keeping Employees Engaged"></Heading>
          <Text content="What should a corporate wellness program include? A company might choose to focus on health fairs or biometric screening. Physical activity might be the focus of another business, while smoking cessation might be right for a company that wants to reduce health issues for smokers and create a better working environment for nonsmokers." />
          <br />
          <Text style={{ fontWeight: 600 }} content="Some of the healthy options that your workforce might enjoy include:" />
          <ul>
            <li><span style={{ fontSize: "30px", paddingRight: "20px" }}>&bull;</span> Smoking cessation</li>
            <li><span style={{ fontSize: "30px", paddingRight: "20px" }}>&bull;</span> Healthy eating, including healthier ways to snack</li>
            <li><span style={{ fontSize: "30px", paddingRight: "20px" }}>&bull;</span> Weight loss</li>
            <li><span style={{ fontSize: "30px", paddingRight: "20px" }}>&bull;</span> Wellness talks and health information</li>
          </ul>

          <br />
          <Text content="Depending on the client’s budget and corporate culture, we might also encourage offering gym memberships. This kind of perk can encourage those who might not join a gym to try it out and to discover a new way of feeling better and more alert. But even without offering bigger perks like gym memberships or yoga classes, Archer Health can help you design a corporate wellness program that fits your budget and motivates your workforce."></Text>
          <br />
          <br />

          <Heading as="h2" content="Tailor Your Program to Fit Your Employees"></Heading>
          <Text content="A tech company might have many employees who enjoy online options, such as yoga classes or meditation. Surveys can be made within the organization to see what type of wellness programs are of most interest. Feeling good about the program is part of the goal. After all, a culture of wellness should be desired, not forced." />
          <br />
          <Text content="Wellness classes can be more inclusive and can bring different parts of the company together. In addition to the use of the survey, you might form a committee whose main focus is wellness and promoting a healthy lifestyle.
"></Text><br />
          <Text content="Where your business is located can also play a part in developing health and wellness initiatives. For example, a company located near water might opt for canoe or kayak classes for employees. Another organization may be near woods or a park with walking trails -- perfect for forming a walking or hiking group, even for short walks at lunchtime. Aside from online classes, wellness should include the outdoors for those who have easy access."></Text><br />

          <Text content="These ideas are a good starting point for changing the way your workforce thinks about health, wellness and exercise. If you would like to develop a more comprehensive corporate wellness program for your staff, we can help!"></Text><br />
          <Text content="Contact us to find out more about how Archer Health can help your organization create a wellness program that gets employees engaged."></Text>
        </div>
      </Container>

    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '60%', '50%'], //<480, <768, >768, >990
  },
  title: {
    fontSize: ['16px', '24px', '32px', '45px'],
    fontWeight: '300',
    color: '#3D636C',
    //letterSpacing: '-0.025em',
    letterSpacing: 0,
    mb: ['20px', '25px'],
    lineHeight: '1.31',
  },
  description: {
    fontSize: ['14px', '16px', '20px'],
    color: '#3D636C',
    lineHeight: '1.5',
    mb: '0',
  },
  btnStyle: {
    minWidth: ['120px', '156px'],
    fontSize: '14px',
    fontWeight: '300',
  },
  outlineBtnStyle: {
    minWidth: ['130px', '156px'],
    fontSize: '14px',
    fontWeight: '500',
    color: '#0f2137',
    p: '5px 10px',
  },
  discountAmount: {
    fontSize: '14px',
    color: '#10AC84',
    mb: 0,
    as: 'span',
    mr: '0.4em',
  },
  discountText: {
    fontSize: '14px',
    color: '#0f2137',
    mb: 0,
    as: 'span',
  },
};

export default BannerSection;
